export default function reducer(
  state = {
    userProfile: {},
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {

    case "CREATE_USER_PROFILE": {
      return { ...state, fetching: false, userProfile: action.payload };
    }
  }
  return state;
}
