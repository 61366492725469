const initialState = {
    loading : null,
    softwareModuleID : 0,
    packagePath : null,
    outId : null,
    message : null,
    softwareModuleCreation : true,
    uploadArtifacts : true,
    downloadArtifacts : true,
    downloadUrl : null,
    statusMessage : null
}

export default function reducer(state = initialState , action) {
    switch(action.type) {
        case "CREATING_SOFTWARE_MODULE":
            return {
                ...state,
                loading : true,
                message : "File upload is in progress... Please wait for few minutes",
                statusMessage : null,
                softwareModuleID : 0,
                uploadArtifacts : false,
                downloadArtifacts : false
            }
        case "SOFTWARE_MODULE_CREATED":
            const data = action.payload.data;
            return {
                ...state,
                softwareModuleID : data.softwareModuleID,
                loading : false,
                message : null,
                softwareModuleCreation: true,
                uploadArtifacts : true,
                statusMessage : "Software module created successfully.",
                downloadArtifacts : false
            }
        case "SOFTWARE_MODULE_NOT_CREATED" : 
            return {
                ...state,
                loading : false,
                message : null,
                softwareModuleCreation : false,
                uploadArtifacts : false,
                statusMessage : action.payload.data,
                downloadArtifacts : false
            }
        case "UPLOADING_ARTIFACTS" : 
            return {
                ...state,
                message : "Uploading Artifacts..."
                
            }
        case "UPLOADED_ARTIFACTS" : 
            return {
                ...state,
                loading : false,
                message : null,
                statusMessage : "Artifacts uploaded successfully.",
                uploadArtifacts : true,
                downloadArtifacts : false,
            }
        case "UPLOADED_ARTIFACTS_FAILED" : 
            return {
                ...state,
                loading : false,
                message : null,
                uploadArtifacts : false,
                statusMessage : action.payload.data,
                downloadArtifacts : false
            }
        case "DOWNLOADING_ARTIFACT" :
            return {
                ...state,
                loading : true,
                message : "File download is in progress... Please wait for few minutes",
                statusMessage : null,
                softwareModuleID : 0,
                uploadArtifacts : false,
                downloadUrl : null,
                downloadArtifacts : true
                
            }
        case "DOWNLOADED_ARTIFACT" :
            const data_download = action.payload.data; 
            return {
                ...state,
                softwareModuleID : data_download.data.id,
                downloadUrl : data_download.data,
                loading : false,
                message : null,
                statusMessage : "Artifact downloaded successfully.",
                uploadArtifacts : false,
                downloadArtifacts : true
            }
        case "DOWNLOADED_ARTIFACT_FAILED" : 
            return {
                ...state,
                loading : false,
                message : null,
                uploadArtifacts : false,
                statusMessage : action.payload.data,
                downloadArtifacts : true
            }                   
    }
    return state;
}