import { getPageCount, insertObjectAtFirstPosition, getIndexOfParticularElemInArray } from '../library/paginationUtility';

export default function reducer(state = {
  users: [],
  selectedUser: [],
  usersCount: 0,
  userModulePageCount: 0,
  rolesAndOtherData: {},
  rolesAndOtherDataFetched: false,
  machineList: {},
  machineListFetched: false,
  subFleets: {},
  subFleetsFetched: true,
  fetching: false,
  fetched: false,
  error: null,
  createStatus:"",
  deleteStatus:"",
  updateStatus:"",
  resetPasswordStatus:"",
  lockStatus:"",
  pageCount: 0,
  resetUserData:[],
  isUserAuthorized:false,
  showEdit:true,
  loading : false
}, action) {

  switch (action.type) {
    
    case "FETCH_USER_DETAILS": {
      return {...state, fetching: true}
    }

    case "FETCH_USER_DETAILS_FULFILLED": {   
      const userCnt =  action.payload.data.data.length; //state.usersCount +
      const perPage = 10;          
      const numberOfPages = getPageCount(userCnt, perPage);
      return {...state,
         fetching: false, 
        fetched: true,
         usersCount:userCnt,
         users: action.payload.data.data,
         pageCount:numberOfPages,
         isUserAuthorized: true}
      }
    case "UPDATE_USER_SUCCESS":{
      let oldUsers = state.users;
      let updateUser = action.payload.data;
      return {...state}

    }

    //for roles
   /* case "FETCH_ALL_ROLES": {
      return {...state, fetching: true}
    }*/
    
   /* case "FETCH_ALL_ROLES_FULFILLED": {   
      //const userCnt = state.usersCount + action.payload.data.data.length;
      return {...state, fetching: false, fetched: true, roles: action.payload.data.data}
    }*/
    //for user types
    case "API_LOADING" :{
      return {...state, loading : true};
      }

      case "STOP_API_LOADING" :{
        return {...state, loading : false};
        }

    case "FETCH_All_ROLES": {
      return { ...state, fetching: true };
    }
    case "FETCH_ALL_USERTYPES": {
      return {...state, fetching: true}
    }
    
    case "USERS_FETCH_ALL_USERTYPES_FULFILLED": {   
      return {...state, fetching: false, fetched: true, userTypes: action.payload.data.data,showEdit:action.payload.showEdit}
    }

    //for creating the user
    case "CREATE_NEWUSER_FULFILLED": {
      const user = action.payload.data.data;
      const newUser = insertObjectAtFirstPosition(state.users, user);      
      const userCnt = newUser.length;      
      const perPage = 10;          
      const numberOfPages = getPageCount(userCnt, perPage);
      state.createStatus = action.payload.data.status;
      return { ...state, fetching: false, fetched: true, users: newUser, createStatus:action.payload.data.status, pageCount:numberOfPages }
    }
    case "CREATE_NEWUSER_REJECTED": {
      return { ...state}
    }


    //FOR UPDATING THE USER DETAILS

    case "UPDATE_USER_DETAILS_SUCCESS":{
      const id = action.payload.request.user_id;
      if(id !== null) {
        const oldUserList = state.users;
        const newUserList = action.payload.response.data;
        var updateUserList = oldUserList.map(item => {
          if(item.user_id==id){
            return action.payload.request;
          }
          else{
            return item;
          }
        
        })
        state.updateStatus = action.payload.response.status;
        return {...state, fetching: false, fetched: true, users:updateUserList, updateStatus:action.payload.response.status}
      }
    }
    case "UPDATE_USER_DETAILS_REJECTED":{
      return { ...state, fetching: false, error: action.payload}
    }

//FOR DELETING THE USERDETAILS
case "DELETE_USER_SUCCESS": {
  const id = action.payload.request.userID;
  if(id !== null) {
    const oldUserList = state.users;
    const newUserList = [];
    if(oldUserList !== undefined && oldUserList.length > 0) {
      for(let i=0;i<=oldUserList.length-1;i++) {
        if(oldUserList[i].user_id === id) {
           delete oldUserList[i];
            } else {
            newUserList.push(oldUserList[i]);                
            }
        }
    }
    const userCnt = newUserList.length;        
    const perPage = 10;           
    const numberOfPages = getPageCount(userCnt, perPage);
    state.deleteStatus = action.payload.response.status;
    return {...state, fetching: false, fetched: true, users: newUserList,
       deleteStatus:action.payload.response.status, pageCount: numberOfPages, usersCount:userCnt}
  }
}
case "DELETE_USER_REJECTED": {
  return {...state, fetching: false, error: action.payload}
}





    
    case "SEARCH_USER_FULFILLED": {  
      let oldUserList = action.payload.data.data;
      const searchUser =   action.payload.request.textToFilter;
      if(searchUser === "" || searchUser === undefined || searchUser === null){
        state.users = oldUserList;    
      } else {
        const searchUsersList = oldUserList.filter(value => {
          if((value.user_name.trim().toLowerCase() === searchUser.trim().toLowerCase())){
            return value;
          }});
          oldUserList = searchUsersList;
        }
        const userCnt = state.usersCount + action.payload.data.data.length;

      return {...state, fetching: false, fetched: true, usersCount:userCnt, users: oldUserList}
    }

    case "SEARCH_USER_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }

    case "FETCH_USER_LIST_FULFILLED": {   
      
      const userCnt = 0 + action.payload.data.data.users.length;
      const perPage = action.payload.request.perPage;   
      const numberOfPages = getPageCount(userCnt, perPage);
      return {...state, fetching: false, fetched: true, usersCount:userCnt, users: action.payload.data.data.users,
              pageCount: numberOfPages}
    }
   
    
    case "ACTIVATE_OR_DEACTIVATE_USER_FULFILLED": {
      const oldData = action.payload.oldData;
      const index = getIndexOfParticularElemInArray(state.users, oldData);
      const users = state.users;
      if(index > -1){
        users[index] = oldData;
      }
      return {...state, users: users }
    }
    
    case "UPDATE_USER_FULFILLED": {
      const oldData = action.payload.data.data.dataModel;
      const index = getIndexOfParticularElemInArray(state.users, oldData);
      const users = state.users;
      if(index > -1){
        users[index] = oldData;
      }
      return {...state, users: users }
    }
    case "CLEAR_USER_DATA": {
      return { ...state, fetching: false, fetched: false, users: [] }
    }
    case "LOCK_OR_UNLOCK_USER_FULFILLED":{
      const id = action.payload.request.selectedUserData.user_id;
      if(id !== null) {
        const oldUserList = state.users;
        const newUserList = action.payload.response.data.users;
        const user = oldUserList.map(obj => newUserList.find(o => o.user_id === obj.id) || obj);
        state.lockStatus = action.payload.response.status;
        return {...state, fetching: false, fetched: true, users:user, lockStatus:action.payload.response.status}
      }
    }
    case "LOCK_OR_UNLOCK_USER_REJECTED":{
      return {...state, fetching: false, error: action.payload}
    } 
    case "FETCH_USER_DETAILS_REJECTED": {
      return {...state, fetching: false, error: action.payload,
        isUserAuthorized:action.payload.isUserAuthorized,
        fetched: false
      }
    }
    case "FETCH_SELECTED_USER": {
      return {...state, fetching: true}
    }
    case "FETCH_SELECTED_USER_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SELECTED_USER_SUCCESS": {
      const usersCt = state.usersCount + action.payload.response.data.users.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(usersCt, perPage);
      return {...state, fetching: false, fetched: true, userCount:usersCt, users: action.payload.response.data.users,  pageCount:numberOfPages, selectedUser :action.payload.response.data}
    }
    case "PASSWORD_RESET_SUCCESS": {    
      state.resetUserData = action.payload.response.data;
      state.resetPasswordStatus = action.payload.response.status;
      return {...state, fetching: false, fetched: true, resetPasswordStatus:action.payload.response.status,resetUserData:action.payload.response.data }
    }
    case "PASSWORD_RESET_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
  }
  return state
}
