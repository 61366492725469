import { getPageCount, insertObjectAtFirstPosition, getIndexOfParticularElemInArray } from '../library/paginationUtility';

export default function reducer(state = {
  users: [],
  packageTypes:[],
  selectedUser: [],
  usersCount: 0,
  userModulePageCount: 0,
  rolesAndOtherData: {},
  rolesAndOtherDataFetched: false,
  machineList: {},
  machineListFetched: false,
  subFleets: {},
  subFleetsFetched: true,
  fetching: false,
  fetched: false,
  error: null,
  createStatus:"",
  deleteStatus:"",
  updateStatus:"",
  resetPasswordStatus:"",
  lockStatus:"",
  pageCount: 0,
  resetUserData:[],
  isUserAuthorized:false
}, action) {

  switch (action.type) {
    
    case "FETCH_PACKAGETYPE_DETAILS": {
      return {...state, fetching: true}
    }

    case "FETCH_PACKAGETYPE_DETAILS_FULFILLED": {   
      const userCnt =  action.payload.data.length; //state.usersCount +
      const perPage = 10;          
      const numberOfPages = getPageCount(userCnt, perPage);
      return {...state, fetching: false, fetched: true, usersCount:userCnt, packageTypes: action.payload.data,pageCount:numberOfPages,isUserAuthorized:true}
    }
    case "FETCH_PACKAGETYPE_DETAILS_REJECTED": {
      return { ...state, fetching: false, error: action.payload ,isUserAuthorized:action.payload.isUserAuthorized};
    }
    case "UPDATE_USER_SUCCESS":{
      let oldUsers = state.users;
      let updateUser = action.payload.data;
      return {...state}

    }

    //for roles
    case "FETCH_ALL_ROLES": {
      return {...state, fetching: true}
    }
    
    case "FETCH_ALL_ROLES_FULFILLED": {   
      return {...state, fetching: false, fetched: true, roles: action.payload.data.data}
    }
    //for user types
    case "FETCH_ALL_USERTYPES": {
      return {...state, fetching: true}
    }
    
    case "FETCH_ALL_USERTYPES_FULFILLED": {   
      //const userCnt = state.usersCount + action.payload.data.data.length;
      return {...state, fetching: false, fetched: true, userTypes: action.payload.data.data}
    }

    //for creating the user
    case "CREATE_NEWPACKAGETYPE_FULFILLED": {
      const packageType = action.payload.data.data;
      const newPackage = insertObjectAtFirstPosition(state.packageTypes, packageType);      
      const packgesCnt = newPackage.length;      
      const perPage = 10;          
      const numberOfPages = getPageCount(packgesCnt, perPage);
      state.createStatus = action.payload.data.status;
      return { ...state, fetching: false, fetched: true, packageTypes: newPackage, createStatus:action.payload.data.status, pageCount:numberOfPages }
    }
    case "CREATE_NEWPACKAGETYPE_REJECTED": {
      return { ...state}
    }


    //FOR UPDATING THE USER DETAILS

    case "UPDATE_PACKAGETYPE_DETAILS_SUCCESS":{
      const id = action.payload.request.package_type_id;
      if(id !== null) {
        const oldUserList = state.packageTypes;
        const newUserList = action.payload.response.data;
        var updateUserList = oldUserList.map(item => {
          if(item.package_type_id==id){
            return action.payload.request;
          }
          else{
            return item;
          }
        
        })
        state.updateStatus = action.payload.response.status;
        return {...state, fetching: false, fetched: true, packageTypes : updateUserList, updateStatus:action.payload.response.status}
      }
    }
    case "UPDATE_PACKAGETYPE_DETAILS_REJECTED":{
      return { ...state, fetching: false, error: action.payload}
    }

//FOR DELETING THE USERDETAILS
case "DELETE_PACKAGETYPE_SUCCESS": {
  const id = action.payload.request.package_type_id;
  if(id !== null) {
    const oldUserList = state.packageTypes;
    const newUserList = [];
    if(oldUserList !== undefined && oldUserList.length > 0) {
      for(let i=0;i<=oldUserList.length-1;i++) {
        if(oldUserList[i].package_type_id === id) {
           delete oldUserList[i];
            } else {
            newUserList.push(oldUserList[i]);                
            }
        }
    }
    const userCnt = newUserList.length;        
    const perPage = 10;           
    const numberOfPages = getPageCount(userCnt, perPage);
    state.deleteStatus = action.payload.response.status;
    return {...state, fetching: false, fetched: true, packageTypes: newUserList,
       deleteStatus:action.payload.response.status, pageCount: numberOfPages, usersCount:userCnt}
  }
}
case "DELETE_PACKAGETYPE_REJECTED": {
  return {...state, fetching: false, error: action.payload}
}



    
case "SEARCH_PACKAGES_FULFILLED": {  
   let oldPackageList = action.payload.data.data;
   const searchPackage =   action.payload.request.textToFilter;
   if(searchPackage === "" || searchPackage === undefined || searchPackage === null){
     state.packageTypes = oldPackageList;    
   } else {
     const searchUsersList = oldPackageList.filter(value => {
       if((value.package_type_name.trim().toLowerCase() === searchPackage.trim().toLowerCase())){
         return value;
       }});
       oldPackageList = searchUsersList;
     }  
     
     const userCnt = action.payload.data.data.length;
     const perPage = 10;          
     const numberOfPages = getPageCount(userCnt, perPage);

   return {...state, fetching: false, fetched: true, usersCount : userCnt, packageTypes: oldPackageList, pageCount: numberOfPages}
 }
    case "SEARCH_PACKAGES_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }

    case "FETCH_USER_LIST_FULFILLED": {   
      
      const userCnt = 0 + action.payload.data.data.users.length;
      const perPage = action.payload.request.perPage;   
      const numberOfPages = getPageCount(userCnt, perPage);
      return {...state, fetching: false, fetched: true, usersCount:userCnt, users: action.payload.data.data.users,
              pageCount: numberOfPages}
    }
   
    
    case "ACTIVATE_OR_DEACTIVATE_USER_FULFILLED": {
      const oldData = action.payload.oldData;
      const index = getIndexOfParticularElemInArray(state.users, oldData);
      const users = state.users;
      if(index > -1){
        users[index] = oldData;
      }
      return {...state, users: users }
    }
    
    case "UPDATE_USER_FULFILLED": {
      const oldData = action.payload.data.data.dataModel;
      const index = getIndexOfParticularElemInArray(state.users, oldData);
      const users = state.users;
      if(index > -1){
        users[index] = oldData;
      }
      return {...state, users: users }
    }
    
    case "CLEAR_USER_DATA": {
      return { ...state, fetching: false, fetched: false, users: [] }
    }
    case "LOCK_OR_UNLOCK_USER_FULFILLED":{
      const id = action.payload.request.selectedUserData.user_id;
      if(id !== null) {
        const oldUserList = state.users;
        const newUserList = action.payload.response.data.users;
        const user = oldUserList.map(obj => newUserList.find(o => o.user_id === obj.id) || obj);
        state.lockStatus = action.payload.response.status;
        return {...state, fetching: false, fetched: true, users:user, lockStatus:action.payload.response.status}
      }
    }
    case "LOCK_OR_UNLOCK_USER_REJECTED":{
      return {...state, fetching: false, error: action.payload}
    } 
    case "FETCH_USER_DETAILS_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SELECTED_USER": {
      return {...state, fetching: true}
    }
    case "FETCH_SELECTED_USER_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SELECTED_USER_SUCCESS": {
      const usersCt = state.usersCount + action.payload.response.data.users.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(usersCt, perPage);
      return {...state, fetching: false, fetched: true, userCount:usersCt, users: action.payload.response.data.users,  pageCount:numberOfPages, selectedUser :action.payload.response.data}
    }
    case "PASSWORD_RESET_SUCCESS": {    
      state.resetUserData = action.payload.response.data;
      state.resetPasswordStatus = action.payload.response.status;
      return {...state, fetching: false, fetched: true, resetPasswordStatus:action.payload.response.status,resetUserData:action.payload.response.data }
    }
    case "PASSWORD_RESET_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
  }
  return state
}
