
export const HOST = process.env.API_URL;
export const FOTAADMIN = "600"

export const DOMAIN_NAME = 'OHW';
export const ROLEMAP = {
  "600": "FOTAADMIN"
}

//pagignation constants
export const PAGESIZE = 10;
export const BATCHSIZE = 100;

export const REPORT_TYPES = ['Data Log', 'Alert Report', 'Fleet Summary', 'Machine Utilization Report', 'Service Report'];
export const FLEET_BATCHSIZE = 10;
export const BRANDCOLOR={
  base:{
    CC:"#E58E1A",
  "CI":"#A71930",
  NH:"#004B93",
  steyr:"#D00019"
},
sub:{
  CC:"#cc7d15",
  "CI":"#901428 ",
  NH:"#044079",
  steyr:"#b30116"
}
}
export const BRANDLOGO={
  CC:"case.png",
  "CI":"case-ih.png",
  NH:"agri.png",
  steyr:"steyr.png"


}
export const COLORS = {

  darkblue: "#00008b",

  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkviolet: "#9400d3",
  aqua: "#00ffff",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  cyan: "#00ffff",
  fuchsia: "#ff00ff",
  indigo: "#4b0082",
  khaki: "#f0e68c",
  magenta: "#ff00ff",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#ffa500",
  pink: "#ffc0cb",
  purple: "#800080",
  violet: "#800080",
  red: "#ff0000",
  silver: "#c0c0c0",
  yellow: "#ffff00",
  darkgrey: "#a9a9a9",
  darkcyan: "#008b8b"
};

export const ALERTPREF = "alertThreshold";
export const ALERT_TYPE_SIGNAL = "Signal Type";

export const OVERRIDEN="Overriden";

//ITRAMSWEB-695
export const SOURCE ="iTramsWeb";

export const CONFIG = {
  discovery_endpoint: process.env.DISCOVERY_END_POINT,
  client_id: process.env.CLIENT_ID,
  redirect_uri: process.env.API_URL+"/packageinfo",
 //redirect_uri: "http://localhost:8002/packageinfo",
  logout_uri: process.env.API_URL,
  post_logout_redirect_uri: process.env.API_URL+"/login",
  state: "UGF0cmljaw==",
  nonce: "148654799357306376458597147538",
  requested_scopes: "openid profile email offline_access",
  styleId: "f8df8cf7-8ded-4434-84a5-0125e36fca0e",
  client_secret:  process.env.CLIENT_SECRET,
};
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";