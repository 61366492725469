import { connect } from "react-redux";
import { addToast, initializeToast } from "../actions/toastsAction";
import * as Constants from "../../constants/Constants";
import { signOutUser } from "../actions/authAction";
import store from "../store";
import axios from "axios";
import autorefresh from "jwt-autorefresh";
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';
let id = 0;

const defaultOptions = {
  color: "#6796e6"
};
export function getParams(request){
  const param = Object.keys(request).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
}).join('&');
return param;
}
export  function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: id++
  }
}
export function showToast(msg) {
  store.dispatch(addToast({ text: msg }));
}
export function showToastError(msg) {
  store.dispatch(addToast({ text: msg, err: true }));
}
export function clearSession(){
  sessionStorage.clear();
  localStorage.clear();
  /*sessionStorage.removeItem("isAuthenticated");
  sessionStorage.removeItem("id_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("typeOfuser");
  localStorage.removeItem("userData");
  localStorage.removeItem("expiresIn");
  if(sessionStorage.getItem("ciam_email_id")){
    localStorage.removeItem("ciam_state");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("ciam_email_id");
    sessionStorage.removeItem("api_auth_token");
    sessionStorage.removeItem("id_token");
    localStorage.removeItem("ciam_refresh_token");
  }*/
}
export function checkTokenExpiry(){
  var tokenforexpirycheck = jwt_decode(sessionStorage.getItem("id_token"));
  if (moment.utc(new Date()) > moment.utc(new Date(tokenforexpirycheck.exp * 1000))) {
   return true;
 } else {
       return false;
 }
}
export function setTokenInAxiosHeader() {
      if(sessionStorage.getItem('ciam_email_id')){
      axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('access_token');
      }else{
           axios.defaults.headers.common['Authorization'] = 'Bearer '+sessionStorage.getItem('id_token');
      }
}
export function setTokenInAxiosForApiAuth() {
  //jwtRefreshtoken();
   axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';  
  if(sessionStorage.getItem('ciam_email_id')){
    axios.defaults.headers.common['Authorization'] = 'Bearer '+sessionStorage.getItem('api_auth_token');
  }else{
       axios.defaults.headers.common['Authorization'] = 'Bearer '+sessionStorage.getItem('id_token');
  }
}

export function jwtRefreshtoken(){
  if(!store.getState().auth.isIdeal || store.getState().packageReducer.loading ){
  }else{
    store.dispatch(signOutUser());
  }
  const reftesh_token = sessionStorage.getItem('refresh_token');
  let token=null;
  if(sessionStorage.getItem("ciam_email_id")){
   token = sessionStorage.getItem('api_auth_token');
  }else{
   token = sessionStorage.getItem('id_token');
  } 
  const request = {
    "grant_type":"refresh_token",
    "refresh_token":reftesh_token
  }
  const params = Object.keys(request).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
  }).join('&');
  const refresh = () => {
    return axios.post(Constants.HOST+"/oauth/token", params, {
      headers: {
    	"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg=",
      }
    }).then((response) => {
      let new_token = response.data.access_token;
      if(sessionStorage.getItem("ciam_email_id")){
        sessionStorage.setItem('api_auth_token',new_token);
        let new_refresh_token = response.data.refresh_token;
        sessionStorage.setItem('refresh_token', new_refresh_token);
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('api_auth_token');
      }else{
        sessionStorage.setItem('id_token',new_token);
      let new_refresh_token = response.data.refresh_token;
      sessionStorage.setItem('refresh_token', new_refresh_token);
      let new_bearer_token = "Bearer "+new_token;
      axios.defaults.headers.common['Authorization'] = new_bearer_token;
      }
      return new_token;
    })
    .catch((err) => {
     // store.dispatch(addSnackbar({ text: "Sorry something went wrong! Please Login again.." }));
      store.dispatch(signOutUser());
    })
  }
  const leadSeconds = () => {
    /** Generate random additional seconds (up to 30 in this case) to append to the lead time to ensure multiple clients dont schedule simultaneous refresh */
    const jitter = Math.floor(Math.random() * 30)

    /** Schedule autorefresh to occur 60 to 90 seconds prior to token expiration */
    return 60 + jitter
  }
  let start = autorefresh({ refresh, leadSeconds })
  start(token);
/*}
  else {
    //store.dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: "Session Timed Out! Please Login again.." });
    store.dispatch(signOutUser());
    
  }*/
}
 

