export default function reducer(state = {
    isIdeal: false,
    isAuthenticated: false,
    fetching: false,
    fetched: false,
    error: null,
    forgotPassFlg: false,
    forgotPassError: false,
    loginFailed:false,
    questions: [],
    secQuesFlg: true,
    passResetLogin: false,
    userName: '',
    changePassError:false,
    selectedParent:'',
    selectedPath:'',
    loginMes:'',
    forgotPassFetching:false,
    forgotPassMess:'',
    loginSuccess:false,
    resetPassFetching:false,
    resetPassMess:'',
    selectedBrand:'',
    isAuthorized: false,
    fromCiamLogin: false,
    loginDetails : {}

}, action) {

    switch (action.type) {
        
        case "AUTH":
            {
                return {
                    ...state,
                    loginFailed:false,
                    fetching: true
                }
            }
        case "AUTH_REJECTED":
            {
                return {
                    ...state,
                    fetching: false,
                    loginFailed:true,
                    loginSuccess:false,
                    isAuthenticated: false,
                    isAuthorized: false,
                    error: action.payload
                }
            }
            case "AUTH_REJECTED_DISP_MES":
            {
                return {
                    ...state,
                    loginMes: action.payload,
                    loginSuccess:false

                }
            }
        case "AUTH_SUCCESS":
            {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    loginFailed: false,
                    isAuthenticated: true,
                    loginSuccess: true,
                    isAuthorized: true,
                    loginMes: '',
                    loginDetails: action.payload
                }
            }
        case "AUTH_SUCCESS_CIAM" : 
        {
           
            return {
                ...state,
                loginDetails : action.payload.loginDetails,
                isAuthenticated : true,
                fromCiamLogin: true
            }
        }    
        case "UN_AUTH":
            {
                return {
                    ...state,
                    fetching: false,
                    fetched: false,
                    isAuthenticated: action.payload.isAuthenticated,
                    loginFailed: true,
                    loginSuccess: false,
                    isAuthorized: action.payload.isAuthorized,
                    fromCiamLogin: action.payload.fromCiamLogin
                }
            }   
             //added to stop generating refresh token when user is ideal
        case "FETCH_REFRESH_TOKEN_REJECTED": {
            return { ...state, isIdeal: true }
        }
        case "FETCH_REFRESH_TOKEN_SUCCESS": {
            return { ...state, isIdeal: false }
        }
        case "FORGOT_PASSWORD":
            {
                if(action.payload==false)
                {
                    return{
                        ...state,
                        forgotPassFetching: false,
                        forgotPassError: false,
                        forgotPassMess:'',
                        forgotPassFlg: action.payload
                    }
                }
                return {
                    ...state,
                    forgotPassFlg: action.payload,
                    loginFailed:false,
                    loginSuccess:false


                }
            }

        case "PASSWORD_UPDATE_SUCCESS":
            {
                return {
                    ...state,
                    forgotPassFlg: false,
                    forgotPassError: false,
                    forgotPassFetching: false,
                    loginSuccess:true,
                    loginMes: action.payload.data.data.responseMessage[0].message
                }
            }
          case "RESET_PASS_FETCHING":{
            return {
                ...state,
                forgotPassFetching: true,

            }
          }

        case "PASSWORD_UPDATE_FAILED":
            {
                if (action.payload.data.status == 500) {
                    return {
                        ...state,
                        forgotPassError: true,
                        forgotPassFetching: false,

                        forgotPassMess:action.payload.data.data.responseMessage[0].message

                    }
                } else {
                    return {
                        ...state,
                        forgotPassError: true,
                        forgotPassFetching: false,

                        forgotPassMess:action.payload.data.data.responseMessage[0].message
                    }
                }
            }

        case "FETCH_QUESTIONS_SUCCESS":
            {
                return {
                    ...state,
                    questions: action.payload.dataModel
                }
            }
        case "INITIAL_LOGIN":
            {
                return {
                    ...state,
                    secQuesFlg: action.payload.questionFlag,
                    passResetLogin: action.payload.initialLogin,
                    userName: action.payload.userName,
                    loginSuccess:false,
                    loginMes: '',
                    fetching: false,
                    fetched: true,
                    loginFailed:false,

                }
            }

        case "CANCEL_CHANGE_PASS":
            {
                return {
                    ...state,
                    secQuesFlg: false,
                    passResetLogin: false,
                    resetPassFetching:false,
                    changePassError:false,
                        resetPassMess:''
                }
            }
            case "UPDATE_PASS_RESET_FLG":{
                return{
                    ...state,
                    resetPassFetching:true,
                    loginSuccess:false
                }
            }
            case "CHANGE_PASSWORD_UPDATE_SUCCESS":
            {
                return {
                    ...state,
                    secQuesFlg: false,
                    passResetLogin: false,
                    resetPassFetching:false,
                    changePassError:false,
                        resetPassMess:'',
                        loginSuccess:true,
                    loginMes: action.payload.data.data.responseMessage[0].message
                }
            }
            case "CHANGE_PASSWORD_UPDATE_FAILED":
            {
                if (action.payload.data.status == 500) {
                    return {
                        ...state,
                        secQuesFlg: false,
                        passResetLogin: false,
                        changePassError:false,
                        resetPassFetching:false,
                        resetPassMess:action.payload.data.data.responseMessage[0].message

                    }
                } else {
                    return {
                        ...state,
                        changePassError: true,
                        resetPassFetching:false,
                        resetPassMess:action.payload.data.data.responseMessage[0].message

                    }
                }
            }

            case "UPDATE_PARENT":{
                localStorage.setItem('parentModule',action.payload.data.module);
                localStorage.setItem('parentPath',action.payload.data.path);
                return{
                    ...state
                }
            }
            case "CLEAR_ERR_MSG":{

                return{
                    ...state,
                      loginFailed:false,
                      loginMes:"",
                }
            }
            case "SET_LOGIN_BRAND":{
              return {
                ...state,selectedBrand:action.payload
              }
            }

    }

    return state
}
