export default function reducer(
  state = {
    domains: {},
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "FETCH_All_DOMAINS": {
      return { ...state, fetching: true };
    }
    case "FETCH_All_DOMAINS_FULFILLED": {
      return { ...state, fetched: true, domains: action.payload.data.data.dataModel };
    }
    case "FETCH_All_DOMAINS_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
  }
  return state;
}
