import React from 'react';



const ActionLoader =(props)=>  {

    return (
      <div class="init-loader">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>

    );


}
export default ActionLoader;
