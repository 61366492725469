import {createToast} from "../library/utility";
import { connect } from 'react-redux';
import { ADD_TOAST, REMOVE_TOAST } from "../../constants/Constants";
export function initializeToast(options = {}) {
  return (dispatch) => {
      dispatch({ payload: options, type: 'ADD_TOAST' });
  }
}
export function addToast(options = {}) {
  return {
    payload: createToast(options),
    type: ADD_TOAST
  };
 
}

export function removeToast(id) {
  return {
    payload: id,
    type: REMOVE_TOAST
  };
}
