import { insertObjectAtFirstPosition ,getPageCount ,getIndexOfParticularElemInArray } from "../library/paginationUtility";
export default function reducer(
  state = {
    roleDetails: [],
    roleModulePageCount: 0,
    roleCount: 0,
    fetching: false,
    fetched: false,
    error: null,
    createStatus:"",
    deleteStatus:"",
    updateStatus:"" ,
    packagesDetails:[] ,
    isUserAuthorized:false ,
    loading : false,
    pageCount: 0
  },
  action
) {
  switch (action.type) {

    case "API_LOADING" :{
      return {...state, loading : true};
      }

      case "STOP_API_LOADING" :{
        return {...state, loading : false};
        }

    case "FETCH_All_ROLES": {
      return { ...state, fetching: true };
    }

    case "CLEAR_ROLE_DATA": {
      return { ...state, fetching: false, fetched: false, roleDetails: [] }
    }
    case "FETCH_All_ROLES_FULFILLED": {
      const roleCount =  action.payload.data.data.length;
      const perPage = 10;          
      const numberOfPages = getPageCount(roleCount, perPage);
      let oldRoles = [];
      oldRoles = action.payload.data.data;
      return { ...state, fetched: true,roles: oldRoles, roleDetails: oldRoles, roleCount: roleCount,isUserAuthorized:true ,roleModulePageCount:numberOfPages };
    }

    case "FETCH_All_ROLES_REJECTED": {
      return { ...state, fetching: false, error: action.payload ,isUserAuthorized:action.payload.isUserAuthorized};
    }

    case "FETCH_All_PACKAGES_FULFILLED": {
      const packagesCount =  action.payload.data.data.length;
      let oldPackages = [];
        oldPackages = action.payload.data.data;
      return { ...state, fetched: true, packagesDetails: oldPackages, pkgsCount: packagesCount };
    }

    case "FETCH_All_PACKAGES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    


    case "INSERT_ROLE": {

      const role = action.payload.data.data;
      const newRoleDetails = insertObjectAtFirstPosition(state.roleDetails, role);
      const roleCount = state.roleCount + 1;
      const perPage = 10;          
      const numberOfPages = getPageCount(roleCount, perPage);
      return { ...state, fetched: true, roleDetails: newRoleDetails,  roleCount: roleCount , roleModulePageCount : numberOfPages};
    }


    case "INSERT_ROLE_FULFILLED": 

    case "INSERT_ROLE_REJECTED": 

    case "UPDATE_ROLE_FULFILLED": {
      const role_id = action.payload.data.role_id; 
      
      if(role_id !== null) {
        const oldRoleList = state.roleDetails;  
           
        const newRoleList = [action.payload.data.data];  
        
        const roles = oldRoleList.map(obj => newRoleList.find(o => o.role_id === obj.role_id) || obj);
        
        state.updateStatus = action.payload.data.status;
        const perPage = 10;          
        const numberOfPages = getPageCount(roles.length, perPage);
              
        return {...state, fetching: false, fetched: true, roleDetails:roles, updateStatus:action.payload.data.status,roleModulePageCount : numberOfPages}
      }
    }
    
    case "UPDATE_ROLE_REJECTED": 
    return { ...state, fetching: false, error: action.payload }

    case "FETCH_ROLE_MENU_FULFILLED": 

    case "FETCH_ROLE_MENU_REJECTED": 

    case "DELETE_ROLE_SUCCESS": {
      const role_id = action.payload.request.roleID;     
     
      if(role_id !== null) {
        const oldRoleList = state.roleDetails;
        const newRoleList = [];
        if(oldRoleList !== undefined && oldRoleList.length > 0) {
          for(let i=0;i<=oldRoleList.length-1;i++) {
            if(oldRoleList[i].role_id === role_id) {
               delete oldRoleList[i];
                } else {
                  newRoleList.push(oldRoleList[i]);
                
                }
            }
        }       
        const roleCnt = newRoleList.length;     
        const perPage = 10;          
        const numberOfPages = getPageCount(roleCnt, perPage);   
        return {...state, fetching: false, fetched: true, roleDetails: newRoleList,
           deleteStatus:action.payload.response.status,roleCount:roleCnt,roleModulePageCount : numberOfPages}
      }
    }
    case "DELETE_ROLE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }

    //Role search functionalilty
    case "SEARCH_ROLE_FULFILLED": {  
       let oldRolesList = action.payload.data.data;
       const searchRole =   action.payload.request.textToFilter;
       //const searchRole = "admin";
       if(searchRole === "" || searchRole === undefined || searchRole === null){
         state.roleDetails = oldRolesList;    
       } else {
         const searchUsersList = oldRolesList.filter(value => {
           if((value.role_name.trim().toLowerCase() === searchRole.trim().toLowerCase())){
             return value;
             //return {...state, fetching: false, fetched: true, usersCount:userCnt, users: value}
           }});
           oldRolesList = searchUsersList;
         }
         const userCnt = state.roleCount + action.payload.data.data.length;
         const perPage = 10;          
        const numberOfPages = getPageCount(userCnt, perPage);
       return {...state, fetching: false, fetched: true, roleCount:userCnt, roleDetails: oldRolesList,roleModulePageCount : numberOfPages}
     }
 
     case "SEARCH_ROLE_REJECTED": {
       return {...state, fetching: false, error: action.payload}
     }


  }
  return state
}
