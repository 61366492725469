
export function fetchPip() {

  return function (dispatch) {

    dispatch({type: "FETCH_PIP"});

  }
}

export function addPip(pipObj) {

  return function (dispatch) {

    dispatch({type: "ADD_TO_PIP", payload: pipObj});

  }
}
export function removePip(index) {

  return function (dispatch) {

    dispatch({type: "REMOVE_PIP", payload: index});

  }
}
