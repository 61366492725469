
import React, { Component } from "react";
import localforage from "localforage";
import jwt_decode from 'jwt-decode';
class FontResizer extends Component {

constructor(props){
  super(props);
  this.userId=null;
  this.state={

    hFontSize:1.0,
      bFontSize:0.9,
      navTextSize:12
  }
}
componentDidMount(){
  const token = localStorage.getItem('iTramsToken')
  if (token) {
    var decoded = jwt_decode(token);
this.userId=decoded.userId;
  let self=this;
  localforage.getItem(decoded.userId+"fontSettings")
      .then((response) => {
if(response==null){

      self.setState({hFontSize:1.0,bFontSize:0.9,navTextSize:11})

}else{

      self.setState({hFontSize:response.hFontSize,bFontSize:response.bFontSize,navTextSize:response.navTextSize})


}

      })
    }
}
resetFontSize(){
this.setState({hFontSize:1.0,bFontSize:0.9,navTextSize:11},()=>localforage.setItem(this.userId+"fontSettings",this.state))



}
handleFontSize(type){
  this.setState(prevState => {
       return {hFontSize: type == 'add' ? prevState.hFontSize + 0.05<=1.40?prevState.hFontSize + 0.05:prevState.hFontSize : prevState.hFontSize - 0.05>=0.8? prevState.hFontSize - 0.05:prevState.hFontSize}
    });
    this.setState(prevState => {
         return {navTextSize: type == 'add' ? prevState.navTextSize + 1<=13?prevState.navTextSize + 1:prevState.navTextSize : prevState.navTextSize - 1>=9? prevState.navTextSize - 1:prevState.navTextSize}
      });
      this.setState(prevState => {
           return {bFontSize: type == 'add' ? prevState.bFontSize + 0.05<=1.3?prevState.bFontSize + 0.05:prevState.bFontSize : prevState.bFontSize - 0.05>=0.80? prevState.bFontSize - 0.05:prevState.bFontSize}
        });

localforage.setItem(this.userId+"fontSettings",this.state)

}
  render() {
    return [
      <div key="FontResizer" class="btn-group font-resizer" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-secondary d-flex justify-content-center" onClick={this.handleFontSize.bind(this,"sub")}><i class="material-icons">remove</i> </button>
    <button type="button" class="btn btn-secondary d-flex justify-content-center" onClick={this.resetFontSize.bind(this)}>A</button>
    <button type="button" class="btn btn-secondary d-flex justify-content-center" onClick={this.handleFontSize.bind(this,"add")}><i class="material-icons">add</i></button>

  </div>,
  <style key="resizeResult" dangerouslySetInnerHTML={{__html: `


    .admin-theader > .row >div >span {
        font-size:${this.state.bFontSize+0.05>1.0?1.0:this.state.bFontSize+0.05}rem!important;
        cursor: pointer;
    }
    .admin-theader > tr >th  {
        font-size:${this.state.bFontSize+0.05>1.0?1.0:this.state.bFontSize+0.05}rem!important;
        cursor: pointer;
    }
    .admin-tbody > tr >td {
       font-size: ${this.state.bFontSize}rem!important;
   }

 .bs-small {
    font-size: ${this.state.bFontSize-0.1}rem!important;
}
 .bs-tiny {
    font-size: ${this.state.bFontSize-0.2}rem!important;
}
 .bs-medium {
    font-size: ${this.state.bFontSize}rem!important;
}
.nav-text{
font-size: ${this.state.navTextSize}px !important;
}
h5, .h5 {
font-size: ${this.state.hFontSize}rem !important;
}
`}} />
];
  }


}


export default FontResizer;
