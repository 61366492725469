import { combineReducers } from "redux"
import auth from "./authReducer"
import toasts from "./toastsReducer"
import snackbars from "./snackbarsReducer"
import domains  from "./domainReducers"
import pipItems from "./PIPReducer"
import userProfile from "./userProfilingReducer"
import packageReducer from './packageReducer'
import userReducer from './UserManagementReducer'
import roleReducer from './RoleManagementReducers'
import packageTypeReducer from './PackageTypesManagmentReducer'
import userTypeReducer from './userTypeManagementReducer'

export const appReducer = combineReducers({
  auth,
  toasts,
  snackbars,
  domains,
  pipItems,
  userProfile,
  packageReducer,
  userReducer,
  roleReducer,
  packageTypeReducer,
  userTypeReducer
})

export const rootReducer = (state, action) => {
  if (action!=undefined && (action.type === 'UN_AUTH' || action.type === 'PRE_AUTH'||action.type === 'SWITCH' || action.type === 'UN_AUTHORIZED')) {
    state = undefined
  }

  return appReducer(state, action)
}


