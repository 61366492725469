import * as Constants from "../../constants/Constants";

export function getRequestObject(pageNumber, sortingAttribute, direction, textToFilter) {
  var request = {
    direction: direction,
    sortingAttribute: sortingAttribute,
    pageSize: Constants.PAGESIZE,
    recordsCountToFetch: Constants.BATCHSIZE,
    pageNumber: pageNumber,
    textToFilter: textToFilter
   
  };
  return request;
}

export function getArrayOfObjectEntries(object){
  let arrayToReturn = [];
  if(object != undefined){
    let keys = Object.keys(object);
    for(let iter=0; iter< keys.length; iter++){
      arrayToReturn[iter] = object[keys[iter]];
    }
  }
  return arrayToReturn;
}

export function updateOldList(oldObjList, newObj) {
  if (oldObjList.length == undefined) {
    oldObjList = Object.values(oldObjList);
  }
  var newObjList = newObj.data.data.dataModel.data;

  let request = newObj.request;
  let insertIndex = request.pageNumber * request.recordsCountToFetch;
  if (newObjList.length == undefined) {
    newObjList = Object.values(newObjList);
  }
  var updatedOldObjList = getMergedList(oldObjList, newObjList, insertIndex);
  return updatedOldObjList;
}

export function getMergedList(oldObjList, newObjList, insertIndex) {
  const newObjLength = newObjList.length;
  for (var i = 0; i < newObjLength; i++, insertIndex++) {
    oldObjList[insertIndex] = newObjList[i];
  }
  return oldObjList;
}

export function getPageCount(totalObjectLength, pageSize) {
  let numberOfPages = Math.floor(totalObjectLength / pageSize);
  if (totalObjectLength % pageSize > 0) {
    numberOfPages = numberOfPages + 1;
  }
  return numberOfPages;
}

export function insertObjectAtFirstPosition(oldObjectlist, object) {
  var newObjectList = [];
  newObjectList = newObjectList.concat(object);
  if (oldObjectlist.length > 0) {
    newObjectList = newObjectList.concat(oldObjectlist);
  }
  return newObjectList;
}

export function getIndexOfParticularElemInArray(objectList, object) {
  let index = -1;
  if (objectList !== undefined) {
    for (let i = 0; i < objectList.length; i++) {
      let localObj = objectList[i];
      if (localObj.encryptedId == object.encryptedId) {
        index = i;
        break;
      }
    }
  }
  return index;
}

export function getDeepCopyArrayObject(existingArray) {
  let clonedArray = [];

  for (let i = 0; i < existingArray.length; i++) {
    let clonedObj = Object.assign({}, existingArray[i]);
    clonedArray.push(clonedObj);
  }
  return clonedArray;
}
