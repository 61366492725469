import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { signOutUser, authorizeUser  } from "../../actions/authAction"
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';

import PIPContainer from "../../pages/PIPContainer";
import FontResizer from "../../components/subcomponents/FontResizer"
import * as Constants from "../../../constants/Constants";
import { showToastError } from "../../library/utility"; 
@connect((store) => {
  return {
    auth: store.auth,
    fromCiamLogin: store.auth.fromCiamLogin
  };
})
export const PrivateRoute = ({ component: ComposedComponent, ...rest }) => {

  class Authentication extends React.Component {

    handleRender(props) {
      var queryParam = this.parseQueryString(window.location.search.substring(1));
      if (!this.props.auth.isAuthenticated && !queryParam.code) {
        return <Redirect to={{
          pathname: '/login',
          state: {
            from: props.location,
            message: 'You need to sign in'
          }
        }} />
      } else {
        if (this.validateToken()) {
          return <ComposedComponent {...props} />
        } else if (props.location.pathname === '/packageinfo') {
          this.props.dispatch(authorizeUser());
          return <Redirect to={{
            pathname: '/login',
            state: {
              from: props.location,
              message: 'You need to sign in'
            }
          }} />
        }
        else {
          this.props.dispatch(signOutUser());
          return <Redirect to={{
            pathname: '/login',
            state: {
              from: props.location,
              message: 'You need to sign in'
            }
          }} />
        }
      }
    }
    authExpiryCheck(props) {
      this.validateToken(props);
    }

    validateToken() {
      // OAUTH REDIRECT HANDLING
      // Handle the redirect back from the authorization server and
      // get an access token from the token endpoint
      var queryParam = this.parseQueryString(window.location.search.substring(1));
      var flag = false;

      if (Object.keys(queryParam).length !== 0) {
        // Check if the server returned an error string
        if (queryParam.error) {
          showToastError("Error returned from authorization server: " + queryParam.error);
        }
        // If the server returned an authorization code, attempt to exchange it for an access token
        if (queryParam.code) {
          // Verify state matches what we set at the beginning
          if (localStorage.getItem("ciam_state") != decodeURIComponent(queryParam.state)) {
            showToastError("Invalid state");
          } else {
            this.sendGetRequest(localStorage.getItem("jwks_uri"), function (request, body) {
              localStorage.setItem("public_key_id", body.keys[0].kid);
              localStorage.setItem("certificate", body.keys[0].x5c);
            });
            if (!sessionStorage.getItem("id_token") && !sessionStorage.getItem("isAuthenticated")) {
              // Exchange the authorization code for an access token
              this.sendPostRequest(localStorage.getItem("token_endpoint"), {
                grant_type: "authorization_code",
                code: queryParam.code,
                client_id: Constants.CONFIG.client_id,
                redirect_uri: Constants.CONFIG.redirect_uri,
                client_secret: Constants.CONFIG.client_secret,
                styleId: Constants.CONFIG.styleId
              }, function (request, body) {
                // Initialize your application now that you have an access token.
                sessionStorage.setItem("access_token", body.access_token);
                sessionStorage.setItem("ciam_refresh_token", body.refresh_token);
                sessionStorage.setItem("id_token", body.id_token);
                sessionStorage.setItem("isAuthenticated", true);
              // localStorage.setItem('expiresIn', body.expires_in);
                //localStorage.setItem('expiresIn', 120);
              }, function (request, error) {
                // This could be an error response from the OAuth server, or an error because the 
                // request failed such as if the OAuth server doesn't allow CORS requests
                showToastError(error.error_description);
              });
            }
           
            flag = this.verifyTokenSignatureAndExpiry();
          }
        }
      } else {
        return true;
      }
      return flag;
    }

    verifyTokenSignatureAndExpiry() {
        var flag = false;
        if (sessionStorage.getItem("id_token")) {
          var decoded = jwt_decode(sessionStorage.getItem("id_token"));
           if (moment.utc(new Date()) > moment.utc(new Date(decoded.exp * 1000))) {
            return false;
          } else {
                window.history.replaceState({}, null, "/packageinfo");
                sessionStorage.setItem("ciam_email_id", decoded.email);
                flag = true;
          }
        }
        return flag;
      }

    // Make a POST request and parse the response as JSON
    sendPostRequest(url, params, success, error) {
      var request = new XMLHttpRequest();
      request.open('POST', url, false);
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      request.onload = function () {
        var body = {};
        try {
          body = JSON.parse(request.response);
        } catch (e) { }
        if (request.status == 200) {
          success(request, body);
        } else {
          error(request, body);
        }
      }
      request.onerror = function () {
        error(request, {});
      }
      var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      request.send(body);
    }

    // Parse a query string into an object
    parseQueryString(string) {
      if (string == "") { return {}; }
      var segments = string.split("&").map(s => s.split("="));
      var queryString = {};
      segments.forEach(s => queryString[s[0]] = s[1]);
      return queryString;
    }

    // Make a GET request and parse the response as JSON
    sendGetRequest(url, success, error) {
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      request.onload = function () {
          var body = {};
          try {
              body = JSON.parse(request.response);
          } catch (e) { }
          if (request.status == 200) {
              success(request, body);
          } else {
              error(request, body);
          }
      }
      request.onerror = function () {
          error(request, {});
      }
      request.send();
  }

    render() {
      return [
        <Route {...rest} key="routes" render={this.handleRender.bind(this)} />
      ]
    }
  }

  return <div class="h-100 d-flex">   <FontResizer />   <PIPContainer /><Authentication /></div>
}
