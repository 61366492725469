import React from "react";
import { fetchPip, removePip } from "../actions/PIPAction";
import { WidthProvider, Responsive } from "react-grid-layout";
import { connect } from "react-redux";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

@connect(store => {
  return {
    pipItems: store.pipItems
  };
})
export default class PIPContainer extends React.Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 3, xxs: 2 },
    rowHeight: 100,
    compactType: null
  };
  constructor(props) {
    super(props);

    this.state = {
      layouts: JSON.parse(JSON.stringify(originalLayouts))
    };
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(fetchPip());
  }
  onLayoutChange(layout, layouts) {
    saveToLS("layouts", layouts);

    this.setState({ layouts });
  }

  createElement(el, index) {
    const Tag = this.getWidget.bind(
      this,
      Object.keys(el)[0],
      Object.values(el)[0]
    );

    return (
      <div
        class="widget card"
        key={index}
        data-grid={{ w: 3, h: 3, x: index * 4, y: index * 4 }}
      >
        <div
          class="widget-options  w-100 d-flex align-items-center bg-white"
          style={{ left: "0", top: "0", height: "30px" }}
        >
          <a
            class="rounded border d-flex text-danger ml-auto mr-2"
            onClick={() => {
              this.props.dispatch(removePip(index));
            }}
          >
            <i class="material-icons bs-large">close</i>
          </a>
        </div>
        <Tag />
      </div>
    );
  }
  getWidget(block, value) {
    switch (block) {
      case "PIP_Machine_Live_Tracking":
        return null;
    }
  }

  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  render() {
    return (
      <div class="w-100" style={{ position: "absolute", top: "6rem" }}>
        <ResponsiveReactGridLayout
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
          onBreakpointChange={this.onBreakpointChange}
          {...this.props}
        >
          {this.props.pipItems
            ? this.props.pipItems.pipItems.map((el, index) =>
                this.createElement(el, index)
              )
            : null}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (localStorage) {
    try {
      ls = JSON.parse(localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (localStorage) {
    localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}
