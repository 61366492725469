
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, browserHistory } from "react-router-dom";
import ActionLoader from "./components/ActionLoader";
import { PrivateRoute } from "./components/Auth/require_auth";
import { Provider } from "react-redux";
import T from "i18n-react";
import store from "./store";
import jwt_decode from "jwt-decode";
import Loadable from "react-loadable";
import "../css/datepicker.css";
import "../css/bootstrap.css";
import "../css/style.css";
import "../css/baseline.css";
import "../css/react-grid-layout/css/styles.css";
import "../css/react-resizable/css/styles.css";
import "../css/react-datepicker.css";


const Loading = () => <ActionLoader />;

const Login = Loadable({
  loader: () => import("./pages/Auth/Login"),
  loading: Loading
});
const Logout = Loadable({
  loader: () => import("./pages/Auth/Logout"),
  loading: Loading
});

const Layout = Loadable({
  loader: () => import("./pages/Layout"),
  loading: Loading
});
const PackageManagement = Loadable({
  loader: () => import("./pages/PackageManagement"),
  loading: Loading
});
const locale = localStorage.getItem("iTramsLocale") || "en";
const app = document.getElementById("app");
const token = sessionStorage.getItem("isAuthenticated");  
const userData = localStorage.getItem("userData");
if (token ) {
  // var decoded = jwt_decode(token);
  T.setTexts(require(`../i18n/${locale}.json`));
  //store.dispatch({ type: "AUTH_SUCCESS" });
  store.dispatch({type: 'AUTH_SUCCESS', payload: JSON.parse(userData)});
  
} else {
  T.setTexts(require(`../i18n/en.json`));
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/ssologin" name="Login Page SSO" component={Login} />
        <Route path="/logout" name="Logout Page" component={Logout} />
        <PrivateRoute path="/" name="Home" component={Layout} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  app
); 
 
