import { showToastError, clearSession ,jwtRefreshtoken,setTokenInAxiosForApiAuth,getParams} from "../library/utility";
import * as Constants from "../../constants/Constants";
import axios from 'axios';
import store from "../store";

export function authenticateUser(uname, pwd) {
	  return function (dispatch) {
	    const request = {
	      "username":uname, 
	      "password":pwd,
	      "grant_type":"password" 
	    }
		const params = getParams(request);
	    axios.post(Constants.HOST+'/oauth/token', params, {
	      headers: {
	    	  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
	          "Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg=",
	      }
	    }).then(response => {
	    if(response.status === 200){
			const token = response.data.access_token;
			const refreshToken = response.data.refresh_token;
			sessionStorage.setItem("refresh_token",refreshToken);
			sessionStorage.setItem("id_token",token)
			localStorage.setItem('expiresIn', response.data.expires_in);
			setTokenInAxiosForApiAuth();
			axios.post(Constants.HOST+'/itrams/users/login', {"user_name": uname, "password": pwd}).then(res => {
			  jwtRefreshtoken();
	          if(res.status === 200){
				localStorage.setItem('userData', JSON.stringify(res.data));
	        	sessionStorage.setItem("isAuthenticated", true);
				sessionStorage.setItem("typeOfuser",'normal');	
	        	dispatch({type: "AUTH_SUCCESS", payload: res.data});
	      	}else {
	        	dispatch({type: "AUTH_REJECTED", payload: res.data.error_code});
	      }
	        }).catch((err) => {   
	          if(err.response.data.error_code === "401") {
	            dispatch({ type: "AUTH_REJECTED", payload: err.response.data });
	          } 
	        })
	      } else {
	        dispatch({ type: "AUTH_REJECTED", payload: "Invalid Username or Password"});
	      }
	    }).catch((err) => {
	      err.response.status == 401 ? 
	        dispatch({ type: "AUTH_REJECTED", payload: "Invalid Username or Password" })
	      : err.response.status === 400 ? 
	        dispatch({ type: "AUTH_REJECTED", payload: "There was an error with your Username/Password combination. Please try again." })
	      : null;
	      dispatch({type: "AUTH_REJECTED", payload: err})
	    })
	  }
	}
	export function authorizeCIAMUser(email) {
	//onRefreshToken();
		const request = {
			"username":email,
			"password":sessionStorage.getItem("access_token"),
			"certificate":localStorage.getItem("certificate"),
			"grant_type":"password"
		  }
		  const params = getParams(request);

		return (dispatch) => {
		  axios.post(Constants.HOST+'/oauth/token',params,{
			  headers:{
					"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
				"Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg="
			  }
		  }).then(response => {
			if(response.status === 200){
			  const token = response.data.access_token;
			  sessionStorage.setItem("api_auth_token",token);
			  sessionStorage.setItem("refresh_token", response.data.refresh_token);
			  localStorage.setItem('expiresIn', response.data.expires_in);
			  setTokenInAxiosForApiAuth();
			  axios.get(Constants.HOST+"/itrams/users/login?ciamEmailId="+email).then(response => {
				jwtRefreshtoken();
				localStorage.setItem('userData', JSON.stringify(response.data));
				sessionStorage.setItem("typeOfuser","ciam");
				dispatch({  type: "AUTH_SUCCESS_CIAM",  payload: {isAuthenticated: true,  isAuthorized: true, fromCiamLogin: true,loginDetails: response.data }});
			  }).catch(error => {
				dispatch({  type: "UN_AUTH",  payload: {isAuthenticated: false,  isAuthorized: false, fromCiamLogin: true }});
			  })
			}else{
				dispatch({  type: "UN_AUTH",  payload: {isAuthenticated: false,  isAuthorized: false, fromCiamLogin: true }});
			}
			}).catch(err=>{
				err.response.status == 401 ? 
				dispatch({ type: "AUTH_REJECTED", payload: "Invalid Username or Password" })
			  : err.response.status === 400 ? 
				dispatch({ type: "AUTH_REJECTED", payload: "There was an error with your Username/Password combination. Please try again." })
			  : null;
			  dispatch({type: "AUTH_REJECTED", payload: err})
			})
		}
	  }
	  export function onRefreshToken(){
		if(!store.getState().auth.isIdeal || store.getState().packageReducer.loading ){
		let token_exp = localStorage.getItem("expiresIn")*1000;
		const interval = setInterval(() => {
		var refresh_token  =sessionStorage.getItem("ciam_refresh_token");
		const request = {
			"grant_type":"refresh_token",
			"refresh_token":refresh_token,
			"client_id": Constants.CONFIG.client_id,
			"client_secret": Constants.CONFIG.client_secret,
		   "scope" : "openid email profile offline_access"
		  }
		  const params = Object.keys(request).map((key) => {
			return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
		  }).join('&')
			axios.post(localStorage.getItem("token_endpoint"),params, {
				headers:{
					 'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},}
			).then(response => {
				sessionStorage.setItem('access_token',response.data.access_token);
				sessionStorage.setItem("id_token", response.data.id_token);
				sessionStorage.setItem('ciam_refresh_token', response.data.refresh_token);
				localStorage.setItem('expiresIn', response.data.expires_in);
			  // localStorage.setItem('expiresIn', 120);
			}).catch((err) => {
			   store.dispatch(signOutUser());
			 });
			 
		  }, token_exp); //1200 seconds
		}else{
			store.dispatch(signOutUser());
		}
	}
/*export function signOutUser() {
		let auth_token="";
		let refresh_token="";
		  if(sessionStorage.getItem("ciam_email_id")){
			  auth_token=sessionStorage.getItem("api_auth_token");
		  }else{
			auth_token=sessionStorage.getItem("access_token");
		  }
		  refresh_token=sessionStorage.getItem("refresh_token");
		setTokenInAxiosForApiAuth();
				  const request = {
					"AUTH-TOKEN": auth_token, 
					"REFRESH-TOKEN": refresh_token,
					"CIAM_ID_TOKEN":sessionStorage.getItem("id_token"),
					"UserType":sessionStorage.getItem("typeOfuser"),
					"CIAM_LOGOUT_ENDPOINT":localStorage.getItem("end_session_endpoint"),
					"post_logout_redirect_uri":Constants.CONFIG.post_logout_redirect_uri,
					"state":Constants.CONFIG.state
				}
		const params = getParams(request);
		  return function (dispatch) {
		axios.post(Constants.HOST+'/itrams/token/revoke-token', params).then((response) => {
		  if(response.status === 200) {
		  clearSession();
		  dispatch({ type: "UN_AUTH", payload: {isAuthenticated: false, isAuthorized: false, fromCiamLogin: false} });
		}
		}).catch(() => {
			clearSession();
		  dispatch({ type: "UN_AUTH", payload: {isAuthenticated: false, isAuthorized: false, fromCiamLogin: false} });
		})
	  }
	}
*/
export function signOutUser() {
	var auth_token="";
	var request="";
	const refresh_token = sessionStorage.getItem("refresh_token");
	if(sessionStorage.getItem("ciam_email_id")){
		 auth_token = sessionStorage.getItem("api_auth_token");
		 request = {
			"AUTH-TOKEN": auth_token, 
			"REFRESH-TOKEN": refresh_token,
			"ciam_email_id":sessionStorage.getItem("ciam_email_id"),
			"userType":localStorage.getItem("typeOfUser")
		}
	}else{
		 auth_token = sessionStorage.getItem("id_token");
		 request = {
			"AUTH-TOKEN": auth_token, 
			"REFRESH-TOKEN": refresh_token
		}
	}
	setTokenInAxiosForApiAuth();
	const params = getParams(request);
	return function (dispatch) {
		axios.post(Constants.HOST+'/itrams/token/revoke-token', params,{headers:{
			'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},}).then((response) => {
	  		if(response.status === 200) {
					if(sessionStorage.getItem("ciam_email_id")){
					var params = {
					id_token_hint: sessionStorage.getItem("id_token"),		
					post_logout_redirect_uri: Constants.CONFIG.post_logout_redirect_uri,
					state: Constants.CONFIG.state
		  		}
		  var finalParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		  var url = localStorage.getItem("end_session_endpoint") + "?" + finalParams;
		  window.location = url;
	  }
	  clearSession();
	  dispatch({ type: "UN_AUTH", payload: {isAuthenticated: false, isAuthorized: false, fromCiamLogin: false} });
	}
	}).catch(() => {
		clearSession();
	  dispatch({ type: "UN_AUTH", payload: {isAuthenticated: false, isAuthorized: false, fromCiamLogin: false} });
	})
  }
}
export function authorizeUser() {
  return (dispatch) => {
    dispatch({ 
      type: "UN_AUTH", 
      payload: {
        isAuthenticated: false, 
        isAuthorized: false, 
        fromCiamLogin: true
      } 
    });
  }
}

export function updateParent(parentModule, parentPath) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_PARENT",
      payload: {
        data: {
          "module": parentModule,
          "path": parentPath
        }
      }
    });
  }
}

